.map-div {
  width: 90%;
  align-self: center;
}

@media only screen and (min-width: 600px) {
  .map-div {
    width: 90%;
    align-self: center;
  }
}

.outermost-container-order-det {
  border-radius: 16px;
  height: 100%;
  margin: 15px;
}
.rectangle-icon-order-det {
  display: flex;
  justify-content: center;
  align-items: center;
}

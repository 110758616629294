.delivery-details {
  // height: 144px;
  border-radius: 12px;
  border: 1px solid #e8ecf5;
  margin-top: 12px;
  padding: 10px;
}

.p-delivery-details {
  color: #8d8e91;
}

.address1-delivery-details {
  font-weight: 500;
}

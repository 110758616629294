.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
  border: 1px solid #e8ecf5;
  margin-top: 12px;
  height: 100%;
  padding: 12px;
}

.timeline-item {
  display: flex;
  padding: 8px;
}
.timeline-item-icon {
  position: relative;
  z-index: 1;
}

.timeline-container::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 80%;
  background-image: url("../../../../assets/images/timelineIcon.svg");
  background-repeat: repeat;
  // background-color: #bfc5ce;
  margin-left: 13px;
  margin-top: 27px;
  z-index: -1;
  padding: 0;
}

.timeline-item-content-p {
  margin-left: 10px;
}

.timeline-item-timestamp {
  padding: 10px 5px 5px 5px;
}

.fe-container {
  border-radius: 12px;
  border: 1px solid #e8ecf5;
  height: 64px;
}

.fe-container-inner-container {
  display: flex;
  padding: 10px;
}

.fe-container-message {
  color: #6b778c;
  margin: 0 12px 0 12px;
}

.fe-container-phone {
  padding: 10px;
}

* {
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    padding: 0;
  }
  /*body {
    overflow-y: scroll;
  }*/
  
  /*MOBILE*/
  .container {
    height: 100vh;
    display: grid;
    grid-template-areas:
      "header"
      "."
      "content"
      "."
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 6rem 1rem auto 1rem 3rem;
  }
  
  .header {
    grid-area: header;
  }
  .sidebar {
    grid-area: sidebar;
    height: fit-content;
    width: 240px;
    z-index: 5;
    display: block;
    transform: translateX(-100%);
    transition: all 250ms ease-in-out;
    display: contents;
    /*overflow-y: auto;*/
    background: var(--grey0);
    border-radius: 0 0.5rem 0.5rem 0;
  }
  .content {
    grid-area: content;
    margin-left: 0;
    margin-right: 0;
    overflow-y: unset;
  }
  .content-component {
    display: grid;
    padding-right: 1rem;
    padding-left: 1rem;
    /* padding-bottom: 5rem;*/
  }
  .right_sidebar {
    grid-area: props;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    padding-left: 1rem;
    padding-right: 0.25rem;
    background: var(--grey0);
    border-radius: 0.5rem 0 0 0.5rem;
  }
  .footer {
    grid-area: footer;
    background-color: var(--grey0);
    padding: 0.625rem 0.5rem;
    letter-spacing: var(--letter-spacing-body);
    font-size: 0.875rem;
    display: inline-flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 3rem;
    z-index: 9;
  }
  .footer_links {
    color: var(--grey70);
    font-weight: 500;
    display: flex;
    font-size: var(--font_size_caption_small);
    letter-spacing: var(--letter_spacing_caption_small);
    margin-left: auto;
  }
  .footer_links a {
    color: var(--grey70);
    text-decoration: none;
    margin-right: 1rem;
    text-align: center;
  }
  .footer_links a:last-of-type {
    margin-right: 0;
  }
  .footer_links a:hover {
    color: var(--blue70);
    text-decoration: none;
  }
  .footer_break {
    display: block;
    font-size: var(--font_size_caption);
    letter-spacing: var(--letter_spacing_caption);
  }
  .footer_text {
    text-align: left;
    width: fit-content;
    float: left;
    color: var(--grey50);
    font-size: var(--font_size_caption);
    letter-spacing: var(--letter_spacing_caption);
  }
  .props_button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    z-index: 10;
  }
  .nowrap {
    white-space: nowrap;
  }
  .right_sidebar_backdrop {
    background-color: unset;
  }
  div[role="tablist"][class*="tab-list"] {
    margin-left: auto;
    margin-right: auto !important;
    width: fit-content;
  }
  /*-----640PX-----*/
  @media screen and (min-width: 640px) {
    .container {
      height: calc(100vh);
      display: grid;
      grid-template-areas:
        "header"
        ". "
        "content "
        ". "
        "footer";
      grid-template-columns: 1fr;
      grid-template-rows: 4rem 1rem auto 1rem 3rem;
    }
    .right_sidebar {
      /*display: grid;*/
      width: 180px;
      height: calc(100vh - 8rem);
      overflow-y: auto;
      overflow-x: hidden;
      position: fixed;
      top: 4.5rem;
      right: 0;
    }
    .content-component {
      display: grid;
      width: 100%;
      padding-right: 1rem;
      padding-left: 1rem;
      padding-bottom: 5rem;
    }
    .content {
      margin-right: 0;
      overflow-y: unset;
      width: calc(100% - 180px);
    }
    .footer {
      padding: 0.25rem 0 0.25rem 1rem;
      bottom: 0;
      position: absolute;

    }
    .footer_text {
      width: auto;
      text-align: left;
      float: left;
      color: var(--grey50);
      font-size: var(--font_size_body);
      line-height: 24px;
      height: 24px;
      margin-top: auto;
      margin-bottom: auto;
    }
  
    .footer_break {
      display: inline-block;
      font-size: var(--font_size_body);
    }
    .footer_links {
      padding-top: initial;
      float: right;
      font-size: var(--font_size_caption);
      letter-spacing: var(--letter_spacing_caption);
    }
    .footer_links,
    .footer_links a {
      margin-right: 1.5rem;
      margin-bottom: auto;
      margin-top: auto;
    }
    .footer_links svg {
      width: 1.25rem;
      height: 1.25rem;
    }
    .props_button {
      display: none;
    }
  }
  /*-----1024PX-----*/
  @media screen and (min-width: 1024px) {
    .container {
      height: 100vh;
      display: grid;
      grid-template-areas:
        "header header header"
        ". . ."
        "sidebar content props"
        ". . ."
        "footer footer footer";
      grid-template-columns: 240px 5fr 200px;
      grid-template-rows: 4.5rem 1rem auto 1rem 3rem;
    }
    .sidebar {
      grid-area: sidebar;
      display: block;
      max-width: 240px;
      width: 240px;
      top: 4.5rem;
      transform: unset;
      box-shadow: none;
      height: auto;
      background: none;
    }
    .right_sidebar {
      width: 200px;
      height: calc(100vh - 9.5rem);
      top: 5.5rem;
      background: none;
      padding-bottom: 0;
      padding-top: 0;
    }
    .content-component {
      display: grid;
      width: 100%;
      float: left;
    }
    .content {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      padding: 0 1rem;
    }
    .nowrap {
      white-space: initial;
    }
  }
  
  @media screen and (min-width: 1440px) {
    /*TEST*/
    div[id="root"] {
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
    }
    .container {
      height: calc(100vh);
      display: grid;
      grid-template-areas:
        "header header"
        ". . ."
        "sidebar content"
        ". . ."
        "footer footer";
      grid-template-columns: 200px 1fr;
      grid-template-rows: 4.5rem 1rem auto 0 3rem;
    }
    .sidebar {
      width: 200px;
      max-width: 200px;
    }
    .content {
      padding: 0;
      padding-left: 0.75rem;
    }
    .content-component {
      width: calc(100% - 200px);
      padding: 0;
    }
    .right_sidebar {
      position: relative;
      top: 0;
    }
    .footer {
      position: absolute;
      left: 0;
    }
  }
  
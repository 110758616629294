.Toastify__close-button {
    color: black !important;
    margin-top: 10px;
  }
  
  .Toastify__toast--error {
    color: black !important;
    padding: 0 !important;
    background-color: white !important;
    padding-right: 10px !important;
    border-radius: 6px;
  }
  
  .Toastify__toast-body {
    margin: 0 !important;
  }
  
  .Toastify__toast--success {
    color: black !important;
    padding: 0 !important;
    background-color: white !important;
    padding-right: 10px !important;
    border-radius: 6px;
  }

  .Toastify__toast-icon {
    display: none !important;
  }
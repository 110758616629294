@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap);
/**NAVBAR*/
div[class*="align-left"] {
  margin-right: auto;
}
div[class*="navbar-heading"] {
  margin-right: auto;
}
._type_desktop__24XkX {
  display: flex;
  height: 4.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0 1rem;
}
._type_desktop__24XkX div[class*="navbar-group"] {
  height: 4.5rem;
}

._type_tablet__1BwYO {
  display: flex;
  height: 3.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0 1rem;
}
._type_tablet__1BwYO div[class*="navbar-group"] {
  height: 3.5rem;
}

._type_mobile__1y1Vw {
  height: 6rem;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0.625rem 0.5rem;
}

/*GROUP ALIGN CENTER*/
._type_center__2O3QC {
  align-content: space-between;
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
}
._type_center__2O3QC a:not(:first-of-type) {
  text-decoration: none;
  padding-left: 0.75rem;
}
._type_mobile__1y1Vw div[class*="navbar-group"] {
  height: initial;
}
._type_mobile__1y1Vw div[class*="center"] {
  position: absolute;
  top: 100%;
  left: 0;
  margin-right: 0;
  -webkit-transform: translate(0%, -100%);
          transform: translate(0%, -100%);
  /*overflow-x: auto;*/
  width: 100%;
  overflow-y: hidden;
  padding: 0 0.5rem 0.625rem 0.5rem;
}

/**/

/*NAVBAR*/
._view_flat__34fOv {
  box-shadow: none;
  background: var(--white);
}
._view_smooth__3xG6s {
  box-shadow: none;
  background: var(--color5);
}
._view_raised__UXm74 {
  box-shadow: var(--shadow2dp);
  background: var(--white);
}
._view_filled__3pOTu {
  box-shadow: var(--shadow2dp);
  background: var(--color70);
}

/* COLORS*/
:root {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/

  /*TABLE*/
  --flat-def-bg-table-hover: var(--color5);
  --flat-def-color-table: var(--color50);
  --flat-def-border-table: var(--color10);
  --flat-def-border2-table: var(--color50);
  --flat-def-borderlast-table: var(--color40);
  --flat-def-color-table-pag: var(--color60);
  --flat-def-color-checkbox: var(--color70);

  --filled-def-bg-table: var(--color40);
  --filled-def-bg-table-hover: var(--color50);
  --filled-def-bg-head-table: var(--color70);
  --filled-def-border-table: var(--color30);
  --filled-def-border2-table: var(--color10);
  --filled-def-head-table-sort: var(--color60);
  --filled-def-color-checkbox: var(--color20);

  --smooth-def-bg-head-table: var(--color10);
  --smooth-def-bg-table: var(--color0);
  --smooth-def-border-table: var(--color10);
  --smooth-def-border2-table: var(--color50);
  --smooth-def-color-table: var(--color50);
  --smooth-def-color-table-pag: var(--color60);

  --raised-def-border-table: var(--color10);
  --raised-def-border2-table: var(--color50);
  --raised-def-prim-color-table: var(--color50);
  /*-------------------*/
}

.color_select__3Rg8Y {
  /*TABLE*/
  --flat-bg-table-hover: var(--color5);
  --flat-color-table: var(--color50);
  --flat-border-table: var(--color10);
  --flat-border2-table: var(--color50);
  --flat-borderlast-table: var(--color40);
  --flat-color-table-pag: var(--color60);
  --flat-color-checkbox: var(--color70);

  --filled-bg-table: var(--color40);
  --filled-bg-table-hover: var(--color50);
  --filled-bg-head-table: var(--color70);
  --filled-border-table: var(--color30);
  --filled-border2-table: var(--color10);
  --filled-head-table-sort: var(--color60);
  --filled-color-checkbox: var(--color20);

  --smooth-bg-head-table: var(--color10);
  --smooth-bg-table: var(--color0);
  --smooth-border-table: var(--color10);
  --smooth-border2-table: var(--color50);
  --smooth-color-table: var(--color50);
  --smooth-color-table-pag: var(--color60);

  --raised-border-table: var(--color10);
  --raised-border2-table: var(--color50);
  --raised-prim-color-table: var(--color50);
  /*-------------------*/
}
.color_primary__3FwI9 {
  --color0: var(--blue0);
  --color5: var(--blue5);
  --color10: var(--blue10);
  --color20: var(--blue20);
  --color30: var(--blue30);
  --color40: var(--blue40);
  --color50: var(--blue50);
  --color60: var(--blue60);
  --color70: var(--blue70);
  --color80: var(--blue80);
  --color90: var(--blue90);
  --color100: var(--blue100);

  --shadow2dp: var(--blueShadow2dp);
  --shadow4dp: var(--blueShadow4dp);
  --shadow8dp: var(--blueShadow8dp);
  --shadow16dp: var(--blueShadow16dp);
  --shadow24dp: var(--blueShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradBlue);
  --gradient-reverse: var(--gradBlueRev);
  /*-----*/
}

.color_danger__7O8v_ {
  --color0: var(--red0);
  --color5: var(--red5);
  --color10: var(--red10);
  --color20: var(--red20);
  --color30: var(--red30);
  --color40: var(--red40);
  --color50: var(--red50);
  --color60: var(--red60);
  --color70: var(--red70);
  --color80: var(--red80);
  --color90: var(--red90);
  --color100: var(--red100);

  --shadow2dp: var(--redShadow2dp);
  --shadow4dp: var(--redShadow4dp);
  --shadow8dp: var(--redShadow8dp);
  --shadow16dp: var(--redShadow16dp);
  --shadow24dp: var(--redShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradRed);
  --gradient-reverse: var(--gradRedRev);
  /*-----*/
}
.color_success__3smDZ {
  --color0: var(--green0);
  --color5: var(--green5);
  --color10: var(--green10);
  --color20: var(--green20);
  --color30: var(--green30);
  --color40: var(--green40);
  --color50: var(--green50);
  --color60: var(--green60);
  --color70: var(--green70);
  --color80: var(--green80);
  --color90: var(--green90);
  --color100: var(--green100);

  --shadow2dp: var(--greenShadow2dp);
  --shadow4dp: var(--greenShadow4dp);
  --shadow8dp: var(--greenShadow8dp);
  --shadow16dp: var(--greenShadow16dp);
  --shadow24dp: var(--greenShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGreen);
  --gradient-reverse: var(--gradGreenRev);
  /*-----------------*/
}
.color_warning__4eeIP {
  --color0: var(--yellow0);
  --color5: var(--yellow5);
  --color10: var(--yellow10);
  --color20: var(--yellow20);
  --color30: var(--yellow30);
  --color40: var(--yellow40);
  --color50: var(--yellow50);
  --color60: var(--yellow60);
  --color70: var(--yellow70);
  --color80: var(--yellow80);
  --color90: var(--yellow90);
  --color100: var(--yellow100);

  --shadow2dp: var(--yellowShadow2dp);
  --shadow4dp: var(--yellowShadow4dp);
  --shadow8dp: var(--yellowShadow8dp);
  --shadow16dp: var(--yellowShadow16dp);
  --shadow24dp: var(--yellowShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradYellow);
  --gradient-reverse: var(--gradYellowRev);
  /*-----------------*/
}
.color_default__P6ti3 {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/
}

.color_primary_alt__1rdDI {
  --color0: var(--indigo0);
  --color5: var(--indigo5);
  --color10: var(--indigo10);
  --color20: var(--indigo20);
  --color30: var(--indigo30);
  --color40: var(--indigo40);
  --color50: var(--indigo50);
  --color60: var(--indigo60);
  --color70: var(--indigo70);
  --color80: var(--indigo80);
  --color90: var(--indigo90);
  --color100: var(--indigo100);

  --shadow2dp: var(--indigoShadow2dp);
  --shadow4dp: var(--indigoShadow4dp);
  --shadow8dp: var(--indigoShadow8dp);
  --shadow16dp: var(--indigoShadow16dp);
  --shadow24dp: var(--indigoShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradIndigo);
  --gradient-reverse: var(--gradIndigoRev);
  /*-----*/
}

.color_danger_alt__3m-wJ {
  --color0: var(--pink0);
  --color5: var(--pink5);
  --color10: var(--pink10);
  --color20: var(--pink20);
  --color30: var(--pink30);
  --color40: var(--pink40);
  --color50: var(--pink50);
  --color60: var(--pink60);
  --color70: var(--pink70);
  --color80: var(--pink80);
  --color90: var(--pink90);
  --color100: var(--pink100);

  --shadow2dp: var(--pinkShadow2dp);
  --shadow4dp: var(--pinkShadow4dp);
  --shadow8dp: var(--pinkShadow8dp);
  --shadow16dp: var(--pinkShadow16dp);
  --shadow24dp: var(--pinkShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradPink);
  --gradient-reverse: var(--gradPinkRev);
  /*-----*/
}
.color_success_alt__2Bror {
  --color0: var(--teal0);
  --color5: var(--teal5);
  --color10: var(--teal10);
  --color20: var(--teal20);
  --color30: var(--teal30);
  --color40: var(--teal40);
  --color50: var(--teal50);
  --color60: var(--teal60);
  --color70: var(--teal70);
  --color80: var(--teal80);
  --color90: var(--teal90);
  --color100: var(--teal100);

  --shadow2dp: var(--tealShadow2dp);
  --shadow4dp: var(--tealShadow4dp);
  --shadow8dp: var(--tealShadow8dp);
  --shadow16dp: var(--tealShadow16dp);
  --shadow24dp: var(--tealShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradTeal);
  --gradient-reverse: var(--gradTealRev);
  /*-----------------*/
}
.color_warning_alt__2DTPZ {
  --color0: var(--orange0);
  --color5: var(--orange5);
  --color10: var(--orange10);
  --color20: var(--orange20);
  --color30: var(--orange30);
  --color40: var(--orange40);
  --color50: var(--orange50);
  --color60: var(--orange60);
  --color70: var(--orange70);
  --color80: var(--orange80);
  --color90: var(--orange90);
  --color100: var(--orange100);

  --shadow2dp: var(--orangeShadow2dp);
  --shadow4dp: var(--orangeShadow4dp);
  --shadow8dp: var(--orangeShadow8dp);
  --shadow16dp: var(--orangeShadow16dp);
  --shadow24dp: var(--orangeShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradOrange);
  --gradient-reverse: var(--gradOrangeRev);
  /*-----------------*/
}

/* END COLORS*/

.navbar {
  height: 3.5rem;
}

.navbar-logo {
  height: 2.5rem;
  margin-bottom: 5px;
}
.map-div {
  width: 90%;
  align-self: center;
}

@media only screen and (min-width: 600px) {
  .map-div {
    width: 90%;
    align-self: center;
  }
}
.fe-container {
  border-radius: 12px;
  border: 1px solid #e8ecf5;
  height: 64px;
}

.fe-container-inner-container {
  display: flex;
  padding: 10px;
}

.fe-container-message {
  color: #6b778c;
  margin: 0 12px 0 12px;
}

.fe-container-phone {
  padding: 10px;
}
.outermost-container-order-det {
  border-radius: 16px;
  height: 100%;
  margin: 15px;
}

.rectangle-icon-order-det {
  display: flex;
  justify-content: center;
  align-items: center;
}
.currStatus-order-status {
  font-weight: 500;
  padding: 10px;
  margin-top: 10px;
}
.delivery-details {
  border-radius: 12px;
  border: 1px solid #e8ecf5;
  margin-top: 12px;
  padding: 10px;
}

.p-delivery-details {
  color: #8d8e91;
}

.address1-delivery-details {
  font-weight: 500;
}
.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
  border: 1px solid #e8ecf5;
  margin-top: 12px;
  height: 100%;
  padding: 12px;
}

.timeline-item {
  display: flex;
  padding: 8px;
}

.timeline-item-icon {
  position: relative;
  z-index: 1;
}

.timeline-container::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 80%;
  background-image: url(/static/media/timelineIcon.dfae8c1d.svg);
  background-repeat: repeat;
  margin-left: 13px;
  margin-top: 27px;
  z-index: -1;
  padding: 0;
}

.timeline-item-content-p {
  margin-left: 10px;
}

.timeline-item-timestamp {
  padding: 10px 5px 5px 5px;
}
.track {
  height: 56px;
  margin-bottom: 5px;
}

.track-component {
  padding: 20px;
  font-weight: 500;
}
.outer-div-error {
  height: 90vh;
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f2f3f6;
}

.inner-div-error {
  height: 168px;
  width: 328px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 16px;
  text-align: center;
}

.p-error-msg {
  color: #d71e1d;
  align-items: center;
  padding: 10px;
  font-weight: 500;
}

.p-error {
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  font-weight: 400;
}

.error-icon-container {
  justify-content: center;
  align-items: center;
}
:root {
  /***NEW PALLETE SCHEME***/
  --black: #000000;
  --white: #ffffff;
  /*PRIMARY COLORS*/
  /*Grey*/
  --grey0: #f7f7fa;
  --grey5: #ededf0;
  --grey10: #e1e1e3;
  --grey20: #d2d2d6;
  --grey30: #b4b4bb;
  --grey40: #9696a0;
  --grey50: #787885;
  --grey60: #5a5b6a;
  --grey70: #4a4b57;
  --grey80: #3a3a44;
  --grey90: #292a31;
  --grey100: #19191d;
  /*Blue*/

  --blue0: #f5f8ff;
  --blue5: #ebf2ff;
  --blue10: #d8e6ff;
  --blue20: #c4daff;
  --blue30: #9dc2ff;
  --blue40: #76a9ff;
  --blue50: #4f91ff;
  --blue60: #2979ff;
  --blue70: #2264d1;
  --blue80: #1b4ea3;
  --blue90: #133774;
  --blue100: #0c2146;
  /*Green*/
  --green0: #f5faf5;
  --green5: #ecf7ed;
  --green10: #dceddd;
  --green20: #cbe5cc;
  --green30: #a9d3ab;
  --green40: #87c289;
  --green50: #65b168;
  --green60: #43a047;
  --green70: #37833b;
  --green80: #2b662e;
  --green90: #1f4921;
  --green100: #132c14;
  /*Yellow*/
  --yellow0: #fff8eb;
  --yellow5: #ffefd1;
  --yellow10: #ffe5b3;
  --yellow20: #ffd98f;
  --yellow30: #f5ce84;
  --yellow40: #ebbf67;
  --yellow50: #e5ae40;
  --yellow60: #d6981b;
  --yellow70: #b88217;
  --yellow80: #8f6512;
  --yellow90: #66480d;
  --yellow100: #463209;
  /*Red*/
  --red0: #fef2f1;
  --red5: #fee8e7;
  --red10: #fddcda;
  --red20: #fccbc8;
  --red30: #faa9a3;
  --red40: #f8877f;
  --red50: #f6655a;
  --red60: #f44336;
  --red70: #c8372d;
  --red80: #9c2b23;
  --red90: #6f1f19;
  --red100: #43130f;
  /*SECONDARY COLORS*/
  /*Indigo*/
  --indigo0: #f7f8fc;
  --indigo5: #eff0fa;
  --indigo10: #e1e4f3;
  --indigo20: #d2d6ed;
  --indigo30: #b4bbe2;
  --indigo40: #97a0d6;
  --indigo50: #7985cb;
  --indigo60: #5c6bc0;
  --indigo70: #4c589e;
  --indigo80: #3b457b;
  --indigo90: #2a3158;
  --indigo100: #1a1e35;
  /*Teal*/
  --teal0: #f0fafa;
  --teal5: #e5f5f5;
  --teal10: #d1ebec;
  --teal20: #bbe2e2;
  --teal30: #8dcfcf;
  --teal40: #60bcbc;
  --teal50: #33a9a9;
  --teal60: #069697;
  --teal70: #057b7c;
  --teal80: #046061;
  --teal90: #034545;
  --teal100: #02292a;
  /*Orange*/
  --orange0: #fff6ed;
  --orange5: #ffefde;
  --orange10: #fee7cd;
  --orange20: #fddcb9;
  --orange30: #fdc68b;
  --orange40: #fcaf5c;
  --orange50: #fb982e;
  --orange60: #fb8200;
  --orange70: #ce6b00;
  --orange80: #a05300;
  --orange90: #733c00;
  --orange100: #452400;
  /*Pink*/
  --pink0: #fff7fa;
  --pink5: #fdedf2;
  --pink10: #fbdce6;
  --pink20: #f9cada;
  --pink30: #f6a8c2;
  --pink40: #f285aa;
  --pink50: #ef6292;
  --pink60: #ec407a;
  --pink70: #c23564;
  --pink80: #97294e;
  --pink90: #6c1e38;
  --pink100: #411222;

  /* SHADOWS */
  --greyShadow2dp: 0px 2px 4px rgba(90, 91, 106, 0.24),
    0px 1px 2px rgba(58, 58, 68, 0.24);
  --greyShadow4dp: 0px 4px 8px rgba(90, 91, 106, 0.2),
    0px 2px 4px rgba(58, 58, 68, 0.2);
  --greyShadow8dp: 0px 8px 16px rgba(90, 91, 106, 0.16),
    0px 4px 8px rgba(58, 58, 68, 0.16);
  --greyShadow16dp: 0px 16px 32px rgba(90, 91, 106, 0.12),
    0px 8px 16px rgba(58, 58, 68, 0.12);
  --greyShadow24dp: 0px 24px 48px rgba(90, 91, 106, 0.08),
    0px 12px 24px rgba(58, 58, 68, 0.08);

  --blueShadow2dp: 0px 2px 4px rgba(41, 121, 255, 0.24),
    0px 1px 2px rgba(27, 78, 163, 0.24);
  --blueShadow4dp: 0px 4px 8px rgba(41, 121, 255, 0.2),
    0px 2px 4px rgba(27, 78, 163, 0.2);
  --blueShadow8dp: 0px 8px 16px rgba(41, 121, 255, 0.16),
    0px 4px 8px rgba(27, 78, 163, 0.16);
  --blueShadow16dp: 0px 16px 32px rgba(41, 121, 255, 0.12),
    0px 8px 16px rgba(27, 78, 163, 0.12);
  --blueShadow24dp: 0px 24px 48px rgba(41, 121, 255, 0.08),
    0px 12px 24px rgba(27, 78, 163, 0.08);

  --redShadow2dp: 0px 2px 4px rgba(252, 203, 200, 0.24),
    0px 1px 2px rgba(156, 43, 35, 0.24);
  --redShadow4dp: 0px 4px 8px rgba(244, 67, 54, 0.2),
    0px 2px 4px rgba(156, 43, 35, 0.2);
  --redShadow8dp: 0px 8px 16px rgba(244, 67, 54, 0.16),
    0px 4px 8px rgba(156, 43, 35, 0.16);
  --redShadow16dp: 0px 16px 32px rgba(244, 67, 54, 0.12),
    0px 8px 16px rgba(156, 43, 35, 0.12);
  --redShadow24dp: 0px 24px 48px rgba(244, 67, 54, 0.08),
    0px 12px 24px rgba(156, 43, 35, 0.08);

  --greenShadow2dp: 0px 2px 4px rgba(67, 160, 71, 0.24),
    0px 1px 2px rgba(43, 102, 46, 0.24);
  --greenShadow4dp: 0px 4px 8px rgba(67, 160, 71, 0.2),
    0px 2px 4px rgba(43, 102, 46, 0.2);
  --greenShadow8dp: 0px 8px 16px rgba(67, 160, 71, 0.16),
    0px 4px 8px rgba(43, 102, 46, 0.16);
  --greenShadow16dp: 0px 16px 32px rgba(67, 160, 71, 0.12),
    0px 8px 16px rgba(43, 102, 46, 0.12);
  --greenShadow24dp: 0px 24px 48px rgba(67, 160, 71, 0.08),
    0px 12px 24px rgba(43, 102, 46, 0.08);

  --yellowShadow2dp: 0px 2px 4px rgba(214, 152, 27, 0.24),
    0px 1px 2px rgba(143, 101, 18, 0.24);
  --yellowShadow4dp: 0px 4px 8px rgba(214, 152, 27, 0.2),
    0px 2px 4px rgba(143, 101, 18, 0.2);
  --yellowShadow8dp: 0px 8px 16px rgba(214, 152, 27, 0.16),
    0px 4px 8px rgba(143, 101, 18, 0.16);
  --yellowShadow16dp: 0px 16px 32px rgba(214, 152, 27, 0.12),
    0px 8px 16px rgba(143, 101, 18, 0.12);
  --yellowShadow24dp: 0px 24px 48px rgba(214, 152, 27, 0.08),
    0px 12px 24px rgba(143, 101, 18, 0.08);

  --indigoShadow2dp: 0px 2px 4px rgba(92, 107, 192, 0.24),
    0px 1px 2px rgba(59, 69, 123, 0.24);
  --indigoShadow4dp: 0px 4px 8px rgba(92, 107, 192, 0.2),
    0px 2px 4px rgba(59, 69, 123, 0.2);
  --indigoShadow8dp: 0px 8px 16px rgba(92, 107, 192, 0.16),
    0px 4px 8px rgba(59, 69, 123, 0.16);
  --indigoShadow16dp: 0px 16px 32px rgba(92, 107, 192, 0.12),
    0px 8px 16px rgba(59, 69, 123, 0.12);
  --indigoShadow24dp: 0px 24px 48px rgba(92, 107, 192, 0.08),
    0px 12px 24px rgba(59, 69, 123, 0.08);

  --tealShadow2dp: 0px 2px 4px rgba(6, 150, 151, 0.24),
    0px 1px 2px rgba(4, 96, 97, 0.24);
  --tealShadow4dp: 0px 4px 8px rgba(6, 150, 151, 0.2),
    0px 2px 4px rgba(4, 96, 97, 0.2);
  --tealShadow8dp: 0px 8px 16px rgba(6, 150, 151, 0.16),
    0px 4px 8px rgba(4, 96, 97, 0.16);
  --tealShadow16dp: 0px 16px 32px rgba(6, 150, 151, 0.12),
    0px 8px 16px rgba(4, 96, 97, 0.12);
  --tealShadow24dp: 0px 24px 48px rgba(6, 150, 151, 0.08),
    0px 12px 24px rgba(4, 96, 97, 0.08);

  --orangeShadow2dp: 0px 2px 4px rgba(251, 130, 0, 0.24),
    0px 1px 2px rgba(160, 83, 0, 0.24);
  --orangeShadow4dp: 0px 4px 8px rgba(251, 130, 0, 0.2),
    0px 2px 4px rgba(160, 83, 0, 0.2);
  --orangeShadow8dp: 0px 8px 16px rgba(251, 130, 0, 0.16),
    0px 4px 8px rgba(160, 83, 0, 0.16);
  --orangeShadow16dp: 0px 16px 32px rgba(251, 130, 0, 0.12),
    0px 8px 16px rgba(160, 83, 0, 0.12);
  --orangeShadow24dp: 0px 24px 48px rgba(251, 130, 0, 0.08),
    0px 12px 24px rgba(160, 83, 0, 0.08);

  --pinkShadow2dp: 0px 2px 4px rgba(236, 64, 122, 0.24),
    0px 1px 2px rgba(151, 41, 78, 0.24);
  --pinkShadow4dp: 0px 4px 8px rgba(236, 64, 122, 0.2),
    0px 2px 4px rgba(151, 41, 78, 0.2);
  --pinkShadow8dp: 0px 8px 16px rgba(236, 64, 122, 0.16),
    0px 4px 8px rgba(151, 41, 78, 0.16);
  --pinkShadow16dp: 0px 16px 32px rgba(236, 64, 122, 0.12),
    0px 8px 16px rgba(151, 41, 78, 0.12);
  --pinkShadow24dp: 0px 24px 48px rgba(236, 64, 122, 0.08),
    0px 12px 24px rgba(151, 41, 78, 0.08);

  /*NEW GRADIENTS*/
  --gradBlue: var(--blue60) 0%, var(--indigo70) 100%;
  --gradBlueRev: var(--indigo70) 0%, var(--blue60) 100%;
  --gradRed: var(--red60) 0%, var(--pink70) 100%;
  --gradRedRev: var(--pink70) 0%, var(--red60) 100%;
  --gradGreen: var(--green60) 0%, var(--teal70) 100%;
  --gradGreenRev: var(--teal70) 0%, var(--green60) 100%;
  --gradYellow: var(--yellow60) 0%, var(--orange70) 100%;
  --gradYellowRev: var(--orange70) 0%, var(--yellow60) 100%;
  --gradGrey: var(--grey40) 0%, var(--grey70) 100%;
  --gradGreyRev: var(--grey70) 0%, var(--grey40) 100%;

  --gradIndigo: var(--indigo60) 0%, var(--blue70) 100%;
  --gradIndigoRev: var(--blue70) 0%, var(--indigo60) 100%;
  --gradPink: var(--pink60) 0%, var(--red70) 100%;
  --gradPinkRev: var(--red70) 0%, var(--pink60) 100%;
  --gradTeal: var(--teal60) 0%, var(--green70) 100%;
  --gradTealRev: var(--green70) 0%, var(--teal60) 100%;
  --gradOrange: var(--orange60) 0%, var(--yellow70) 100%;
  --gradOrangeRev: var(--yellow70) 0%, var(--orange60) 100%;

  /*Ligth*/
  --gradBlueL: var(--blue10) 0%, var(--indigo30) 100%;
  --gradBlueRevL: var(--indigo30) 0%, var(--blue10) 100%;
  --gradRedL: var(--red10) 0%, var(--pink30) 100%;
  --gradRedRevL: var(--pink30) 0%, var(--red10) 100%;
  --gradGreenL: var(--green10) 0%, var(--teal30) 100%;
  --gradGreenRevL: var(--teal30) 0%, var(--green10) 100%;
  --gradYellowL: var(--yellow10) 0%, var(--orange30) 100%;
  --gradYellowRevL: var(--orange30) 0%, var(--yellow10) 100%;
  --gradGreyL: var(--grey10) 0%, var(--grey40) 100%;
  --gradGreyRevL: var(--grey40) 0%, var(--grey10) 100%;
}

html,
body {
  font-size: 100%;
  /*font-size: var(--font-size-Body1);*/
  font-family: var(--font-primary);
  font-style: normal;
  line-height: initial;
  letter-spacing: var(--letter-spacing-Body1);
  font-weight: var(--font-weight-Body1);
  color: var(--grey100);
}
button {
  font-family: var(--font-primary);
}

/*Remove blue background on click*/
input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/**/
* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
  outline-offset: 0;
}

.bp3-control input:focus ~ .bp3-control-indicator {
  outline: none;
  outline-offset: 0;
}

.bp3-button:not([class*="bp3-intent-"]) {
  box-shadow: none;
  background-color: unset;
  background-image: none;
  color: unset;
}

.bp3-button:not([class*="bp3-intent-"]):hover {
  box-shadow: none;
  background-clip: unset;
  background-color: unset;
}

.bp3-button .bp3-icon {
  color: unset;
}

label.bp3-label {
  font-size: 1rem;
  font-weight: bold;
  text-align: initial;
  margin-bottom: 0.625rem;
  margin-right: 1rem;
}

@charset "UTF-8";

:root {
  --roboto: "Roboto", sans-serif;
  --quicksand: "Quicksand", sans-serif;
  --inter: "Inter", sans-serif;
  --plex: "IBM Plex Sans", sans-serif;
  --lato: "Lato", sans-serif;
  --manrope: "Manrope", sans-serif;

  /*Font sizes*/
  --font_size_h1: 6rem;
  --font_size_h2: 3.75rem;
  --font_size_h3: 3rem;
  --font_size_h4: 2.125rem;
  --font_size_h5: 1.5rem;
  --font_size_h6: 1.25rem;
  --font_size_body: 1rem;
  --font_size_body_large: 1.25rem;
  --font_size_body_small: 0.875rem;
  --font_size_button: 0.875rem;
  --font_size_caption: 0.75rem;
  --font_size_caption_small: 0.625rem;
  --font_size_overline: 0.75rem;
  --font_size_overline_small: 0.625rem;

  /*Font weight*/
  --font_weight_h1: 700;
  --font_weight_h2: 700;
  --font_weight_h3: 700;
  --font_weight_h4: 700;
  --font_weight_h5: 700;
  --font_weight_h6: 700;
  --font_weight_body: 400;
  --font_weight_body_medium: 500;
  --font_weight_button: 700;
  --font_weight_caption: 400;
  --font_weight_caption_small: 500;
  --font_weight_overline: 600;

  /*Letter spacing*/
  --letter_spacing_h1: -0.026em;
  --letter_spacing_h2: -0.025em;
  --letter_spacing_h3: -0.01em;
  --letter_spacing_h4: 0;
  --letter_spacing_h5: 0;
  --letter_spacing_h6: 0.0075em;
  --letter_spacing_body: 0.0275em;
  --letter_spacing_body_bold: 0.022em;
  --letter_spacing_body_large: 0.0075em;
  --letter_spacing_body_large_bold: 0.004em;
  --letter_spacing_body_small: 0.018em;
  --letter_spacing_button: 0.054em;
  --letter_spacing_caption: 0.033em;
  --letter_spacing_caption_small: 0.06em;
  --letter_spacing_overline: 0.0168em;
  --letter_spacing_overline_small: 0.15em;
}

.roboto {
  font-family: "Roboto", sans-serif;
  font-family: var(--roboto);
}
.quicksand {
  font-family: "Quicksand", sans-serif;
  font-family: var(--quicksand);
}
.inter {
  font-family: "Inter", sans-serif;
  font-family: var(--inter);
}
.lato {
  font-family: "Lato", sans-serif;
  font-family: var(--lato);
}
.plex {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--plex);
}
.monrope {
  font-family: "Manrope", sans-serif;
  font-family: var(--manrope);
}

.h1,
.H1 {
  font-weight: 700;
  font-weight: var(--font_weight_h1);
  font-size: 6rem;
  font-size: var(--font_size_h1);
  letter-spacing: -0.026em;
  letter-spacing: var(--letter_spacing_h1);
}

.h2,
.H2 {
  font-weight: 700;
  font-weight: var(--font_weight_h2);
  font-size: 3.75rem;
  font-size: var(--font_size_h2);
  letter-spacing: -0.025em;
  letter-spacing: var(--letter_spacing_h2);
}

.h3,
.H3 {
  font-weight: 700;
  font-weight: var(--font_weight_h3);
  font-size: 3rem;
  font-size: var(--font_size_h3);
  letter-spacing: -0.01em;
  letter-spacing: var(--letter_spacing_h3);
}

.h4,
.H4 {
  font-weight: 700;
  font-weight: var(--font_weight_h4);
  font-size: 2.125rem;
  font-size: var(--font_size_h4);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h4);
}

.h5,
.H5 {
  font-weight: 700;
  font-weight: var(--font_weight_h5);
  font-size: 1.5rem;
  font-size: var(--font_size_h5);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h5);
}

.h6,
.H6 {
  font-weight: 700;
  font-weight: var(--font_weight_h6);
  font-size: 1.25rem;
  font-size: var(--font_size_h6);
  letter-spacing: 0.0075em;
  letter-spacing: var(--letter_spacing_h6);
}

.body,
.BodyBig {
  font-weight: 400;
  font-weight: var(--font_weight_body);
  font-size: 1rem;
  font-size: var(--font_size_body);
  letter-spacing: 0.0275em;
  letter-spacing: var(--letter_spacing_body);
}

.button_text {
  font-weight: 700;
  font-weight: var(--font_weight_button);
  font-size: 0.875rem;
  font-size: var(--font_size_button);
  letter-spacing: 0.054em;
  letter-spacing: var(--letter_spacing_button);
}

.caption {
  font-weight: 400;
  font-weight: var(--font_weight_caption);
  font-size: 0.75rem;
  font-size: var(--font_size_caption);
  letter-spacing: 0.033em;
  letter-spacing: var(--letter_spacing_caption);
}

.overline {
  font-weight: 600;
  font-weight: var(--font_weight_overline);
  font-size: 0.75rem;
  font-size: var(--font_size_overline);
  letter-spacing: 0.0168em;
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
}

:root {
  --step: 0.25rem;
  /* BREAKPOINTS */
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;

  /* SIZE STEPS */
  --s0: 0;
  --s1px: 1px;
  --sAuto: auto;
  --s100vh: 100vh;
  --s100vw: 100vw;
  --sFull: 100%;

  /*Percents*/
  --s10p: 10%;
  --s20p: 20%;
  --s25p: 25%;
  --s30p: 30%;
  --s33p: 33.333333%;
  --s40p: 40%;
  --s50p: 50%;
  --s60p: 60%;
  --s66p: 66.666667%;
  --s70p: 70%;
  --s75p: 75%;
  --s80p: 80%;
  --s90p: 90%;

  /*Steps*/
  --s4: 0.25rem; /*4px*/
  --s8: 0.5rem; /*8px*/
  --s12: 0.75rem;
  --s16: 1rem;
  --s20: 1.25rem;
  --s24: 1.5rem;
  --s28: 1.75rem;
  --s32: 2rem;
  --s36: 2.25rem;
  --s40: 2.5rem;
  --s44: 2.75rem;
  --s48: 3rem;
  --s52: 3.25rem;
  --s56: 3.5rem;
  --s60: 3.75rem;
  --s64: 4rem;
  --s68: 4.25rem;
  --s72: 4.5rem;
  --s76: 4.75rem;
  --s80: 5rem;
  --s84: 5.25rem;
  --s88: 5.5rem;
  --s92: 5.75rem;
  --s96: 6rem;
  --s100: 6.25rem; /*100px*/
  --s128: 8rem;
  --s160: 10rem;
}

:root {
  /*Fonts*/
  --font-primary: var(--inter);
  --font_primary: var(--inter);

  --font-secondary: var(--inter); /** Used for captions */
  --font_secondary: var(--inter);

  --font-family-button: var(--inter);
  --font_family_button: var(--inter);
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

/*body {
  overflow-y: scroll;
}*/
/*MOBILE*/
.container {
  height: 100vh;
  display: grid;
  grid-template-areas: "header" "." "content" "." "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 6rem 1rem auto 1rem 3rem;
}

.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 240px;
  z-index: 5;
  display: block;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 250ms ease-in-out;
  display: contents;
  /*overflow-y: auto;*/
  background: var(--grey0);
  border-radius: 0 0.5rem 0.5rem 0;
}

.content {
  grid-area: content;
  margin-left: 0;
  margin-right: 0;
  overflow-y: unset;
}

.content-component {
  display: grid;
  padding-right: 1rem;
  padding-left: 1rem;
  /* padding-bottom: 5rem;*/
}

.right_sidebar {
  grid-area: props;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 0.25rem;
  background: var(--grey0);
  border-radius: 0.5rem 0 0 0.5rem;
}

.footer {
  grid-area: footer;
  background-color: var(--grey0);
  padding: 0.625rem 0.5rem;
  letter-spacing: var(--letter-spacing-body);
  font-size: 0.875rem;
  display: inline-flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 3rem;
  z-index: 9;
}

.footer_links {
  color: var(--grey70);
  font-weight: 500;
  display: flex;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  margin-left: auto;
}

.footer_links a {
  color: var(--grey70);
  text-decoration: none;
  margin-right: 1rem;
  text-align: center;
}

.footer_links a:last-of-type {
  margin-right: 0;
}

.footer_links a:hover {
  color: var(--blue70);
  text-decoration: none;
}

.footer_break {
  display: block;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}

.footer_text {
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
  color: var(--grey50);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}

.props_button {
  position: fixed;
  bottom: 4rem;
  right: 1rem;
  z-index: 10;
}

.nowrap {
  white-space: nowrap;
}

.right_sidebar_backdrop {
  background-color: unset;
}

div[role=tablist][class*=tab-list] {
  margin-left: auto;
  margin-right: auto !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/*-----640PX-----*/
@media screen and (min-width: 640px) {
  .container {
    height: 100vh;
    display: grid;
    grid-template-areas: "header" ". " "content " ". " "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1rem auto 1rem 3rem;
  }
  .right_sidebar {
    /*display: grid;*/
    width: 180px;
    height: calc(100vh - 8rem);
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    top: 4.5rem;
    right: 0;
  }
  .content-component {
    display: grid;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 5rem;
  }
  .content {
    margin-right: 0;
    overflow-y: unset;
    width: calc(100% - 180px);
  }
  .footer {
    padding: 0.25rem 0 0.25rem 1rem;
    bottom: 0;
    position: absolute;
  }
  .footer_text {
    width: auto;
    text-align: left;
    float: left;
    color: var(--grey50);
    font-size: var(--font_size_body);
    line-height: 24px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .footer_break {
    display: inline-block;
    font-size: var(--font_size_body);
  }
  .footer_links {
    padding-top: initial;
    float: right;
    font-size: var(--font_size_caption);
    letter-spacing: var(--letter_spacing_caption);
  }
  .footer_links,
.footer_links a {
    margin-right: 1.5rem;
    margin-bottom: auto;
    margin-top: auto;
  }
  .footer_links svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  .props_button {
    display: none;
  }
}
/*-----1024PX-----*/
@media screen and (min-width: 1024px) {
  .container {
    height: 100vh;
    display: grid;
    grid-template-areas: "header header header" ". . ." "sidebar content props" ". . ." "footer footer footer";
    grid-template-columns: 240px 5fr 200px;
    grid-template-rows: 4.5rem 1rem auto 1rem 3rem;
  }
  .sidebar {
    grid-area: sidebar;
    display: block;
    max-width: 240px;
    width: 240px;
    top: 4.5rem;
    -webkit-transform: unset;
            transform: unset;
    box-shadow: none;
    height: auto;
    background: none;
  }
  .right_sidebar {
    width: 200px;
    height: calc(100vh - 9.5rem);
    top: 5.5rem;
    background: none;
    padding-bottom: 0;
    padding-top: 0;
  }
  .content-component {
    display: grid;
    width: 100%;
    float: left;
  }
  .content {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    padding: 0 1rem;
  }
  .nowrap {
    white-space: initial;
  }
}
@media screen and (min-width: 1440px) {
  /*TEST*/
  div[id=root] {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  .container {
    height: 100vh;
    display: grid;
    grid-template-areas: "header header" ". . ." "sidebar content" ". . ." "footer footer";
    grid-template-columns: 200px 1fr;
    grid-template-rows: 4.5rem 1rem auto 0 3rem;
  }
  .sidebar {
    width: 200px;
    max-width: 200px;
  }
  .content {
    padding: 0;
    padding-left: 0.75rem;
  }
  .content-component {
    width: calc(100% - 200px);
    padding: 0;
  }
  .right_sidebar {
    position: relative;
    top: 0;
  }
  .footer {
    position: absolute;
    left: 0;
  }
}
.footer-outer-div {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e8ecf5;
  margin-top: 10px;
}
.Toastify__close-button {
    color: black !important;
    margin-top: 10px;
  }
  
  .Toastify__toast--error {
    color: black !important;
    padding: 0 !important;
    background-color: white !important;
    padding-right: 10px !important;
    border-radius: 6px;
  }
  
  .Toastify__toast-body {
    margin: 0 !important;
  }
  
  .Toastify__toast--success {
    color: black !important;
    padding: 0 !important;
    background-color: white !important;
    padding-right: 10px !important;
    border-radius: 6px;
  }

  .Toastify__toast-icon {
    display: none !important;
  }

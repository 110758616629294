.outer-div-error {
  height: 90vh;
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f2f3f6;
}
.inner-div-error {
  height: 168px;
  width: 328px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 16px;
  text-align: center;
}

.react-calendar {

 // position: fixed !important;
  //top: calc(100% - 479px) !important;
  
  }

.p-error-msg {
  color: #d71e1d;
  align-items: center;
  padding: 10px;
  font-weight: 500;
}

.p-error {
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  font-weight: 400;
}

.error-icon-container {
  justify-content: center;
  align-items: center;
}
